<template>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <!-- Search Input -->
        <div class="bg-surface border border-blue-200 rounded-lg p-4 max-lg:p-2 mb-6">
            <div class="flex items-center mb-4">
                <svg
                    class="h-6 w-6 text-blue-500 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2a1 1 0 01-.293.707L13 13v7a1 1 0 01-.293.707l-2 2A1 1 0 019 23V13L3.293 6.707A1 1 0 013 6V4z"
                    />
                </svg>
                <p class="text-gray-800 font-semibold text-lg">Caută politicieni...</p>
            </div>
            <div class="relative">
                <input
                    v-model="search"
                    @keyup.enter="fetchPoliticians"
                    type="text"
                    placeholder="Caută politicieni..."
                    class="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-main focus:border-main"
                />
                <div v-if="loading" class="absolute my-auto inset-y-0 right-0 flex items-center pr-3">
                    <svg
                        class="animate-spin h-5 w-5 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            class="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                        ></circle>
                        <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                </div>
            </div>
        </div>

        <!-- Politician Cards -->
        <div class="grid grid-cols-3 max-md:!grid-cols-1 gap-6">
            <div
                v-for="politician in politicians"
                :key="politician.id"
                @click="navigateToPolitician(politician.slug)"
                class=" gap-6 cursor-pointer bg-white rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300 overflow-hidden border border-gray-200"
            >
                <div class="p-6 flex flex-col h-full">
                    <div class="flex items-center mb-4">
                        <div class="w-16 h-16 bg-gray-300 rounded-full mr-4 flex-shrink-0 flex items-center justify-center text-2xl font-bold text-white">
                            {{ politician.name.charAt(0) }}
                        </div>
                        <div>
                            <h2 class="text-xl font-bold text-gray-900 line-clamp-2">{{ politician.name }}</h2>
                        </div>
                    </div>
                    <div class="mt-auto flex justify-between items-center">
                        <div class="text-gray-600">
                            <span class="font-semibold text-2xl p-1">{{ politician.affirmations_count }}</span>
                            <span class="text-sm">afirmații</span>
                        </div>
                        <div class="flex items-center">
                            <span class="text-sm text-main font-semibold mr-2">Află mai multe despre →</span>
                            <svg
                                class="h-5 w-5 text-blue-600"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path d="M9 5l7 7-7 7"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <LoadingSpinner v-if="loading"></LoadingSpinner>

        <div v-if="hasMorePages" class="mt-8 text-center">
            <button
                @click="loadMore"
                class="w-full sm:w-auto bg-blue-500 text-white py-3 px-6 rounded-md hover:bg-blue-600 transition duration-200 text-lg font-semibold"
            >
                Încarcă mai mulți politicieni
            </button>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { ref } from "vue";
import LoadingSpinner from "../Components/LoadingSpinner.vue";
import {router} from "@inertiajs/vue3";

export default {
    name: "PoliticiansIndex",
    components: {LoadingSpinner},
    props: {
        initialPoliticians: Array,
        initialHasMorePages: Boolean,
    },
    setup(props) {
        const politicians = ref(props.initialPoliticians);
        const search = ref("");
        const perPage = ref(24);
        const hasMorePages = ref(props.initialHasMorePages);
        const loading = ref(false);

        const fetchPoliticians = async () => {
            loading.value = true;
            try {
                const response = await axios.get("/api/politicians", {
                    params: {
                        search: search.value,
                        perPage: perPage.value,
                    },
                });
                politicians.value = response.data.politicians;
                hasMorePages.value = response.data.hasMorePages;
            } catch (error) {
                console.error("Failed to fetch politicians:", error);
            } finally {
                loading.value = false;
            }
        };

        const loadMore = async () => {
            perPage.value += 24;
            await fetchPoliticians();
        };

        const navigateToPolitician = (slug) => {
            router.visit(route("politician", slug));
        };

        return {
            politicians,
            search,
            perPage,
            hasMorePages,
            loading,
            fetchPoliticians,
            loadMore,
            navigateToPolitician,
        };
    },
};
</script>
